<template>
  <v-flex>
    <br>
    <v-card
      class="mx-auto"
      shaped
      elevation="3"
      max-width="98%"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">2020 - 2023</div>
          <v-list-item-title class="headline mb-1">PhD Degree</v-list-item-title>
          <v-list-item-title class="title mb-1">Computer Science</v-list-item-title>
          <v-list-item-subtitle>Ecole Nationale de l'Aviation Civile / Onera, The French Aerospace Lab</v-list-item-subtitle>
          <v-list-item-subtitle>Institut National des sciences appliquées - Toulouse - France</v-list-item-subtitle>
          <v-list-item-subtitle>École doctorale Systèmes</v-list-item-subtitle>
        </v-list-item-content>

        <v-img max-width="150" src="@/assets/enac_grande.jpg"></v-img>
      </v-list-item>
      <v-card-text>
        <strong>Thesis: Modelling, Simulating and Emulating Distributed Applications in Swarms of Cyber-Physical Systems Deployed in Dynamic Networks</strong>
      </v-card-text>
    </v-card>
    <br>
    <!-- ------------------------------------------------------------------------ -->
    <v-card
      class="mx-auto"
      shaped
      elevation="3"
      max-width="98%"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">2017 - 2019</div>
          <v-list-item-title class="headline mb-1">Master's Degree</v-list-item-title>
          <v-list-item-title class="title mb-1">Informatics Engineering</v-list-item-title>
          <v-list-item-subtitle>Universidade do Minho - Braga - Portugal</v-list-item-subtitle>
          <v-list-item-subtitle>School of Engineering</v-list-item-subtitle>
          <v-list-item-subtitle>Department of Informatics</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar
          tile
          size="80"
          color="grey"
        >
        <v-img src="@/assets/uminho.png"></v-img>
        </v-list-item-avatar>
      </v-list-item>
      <v-card-text>
        <strong>Dissertation: Energy-Aware Gossip Protocol for Wireless Sensors Networks</strong>
      </v-card-text>
    </v-card>
    <br>
    <!-- ------------------------------------------------------------------------ -->
    <v-card
      class="mx-auto"
      shaped
      elevation="3"
      max-width="98%"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">2005</div>
          <v-list-item-title class="headline mb-1">Specialization Degree</v-list-item-title>
          <v-list-item-title class="title mb-1">Software Testing</v-list-item-title>
          <v-list-item-subtitle>Universidade de Pernambuco - Recife - Brazil</v-list-item-subtitle>
          <v-list-item-subtitle>Centro de Informática</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar
          tile
          size="80"
          color="grey"
        >
        <v-img src="@/assets/ufpe.jpg"></v-img>
        </v-list-item-avatar>
      </v-list-item>
      <v-card-text>
        <strong>Field: Test Engineering for Embedded Systems</strong>
      </v-card-text>
    </v-card>
    <br>
    <!-- ------------------------------------------------------------------------ -->
    <v-card
      class="mx-auto"
      shaped
      elevation="3"
      max-width="98%"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">1998-2004</div>
          <v-list-item-title class="headline mb-1">Barchelor Degree</v-list-item-title>
          <v-list-item-title class="title mb-1">Electrical Engineering</v-list-item-title>
          <v-list-item-subtitle>Universidade de Campina Grande - Campina Grande - Brazil</v-list-item-subtitle>
          <v-list-item-subtitle>Centro de Ciências e Tecnologia</v-list-item-subtitle>
          <v-list-item-subtitle>Departamento de Engenharia Elétrica</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar
          tile
          size="80"
          color="grey"
        >
        <v-img src="@/assets/ufcg.jpg"></v-img>
        </v-list-item-avatar>
      </v-list-item>
      <v-card-text>
        <strong>Emphasis: Control and Automation</strong>
      </v-card-text>
    </v-card>
    <br>
    <!-- ------------------------------------------------------------------------ -->
  </v-flex>
</template>

<script>
export default {

}
</script>

<style>

</style>
