<template>
  <v-footer
    dense
    inset
    app
    class="font-weight-this; overline"
  >
    <v-col
      class="text-right"
      cols="12"
    >
      BrunoCF {{ new Date().getFullYear() }} — <a href="https://github.com/brunobcfum/homepage">Fork me</a>
    </v-col>
  </v-footer>
</template>

<script>
export default {

}
</script>

<style>

</style>
