<template>
  <v-flex>
    <br>
    <v-card
      class="mx-auto"
      shaped
      elevation="3"
      max-width="98%"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">2023 - Now</div>
          <v-list-item-title class="headline mb-1">Senior R&D Engineer</v-list-item-title>
          <v-list-item-title class="title mb-1">Hitachi Energy</v-list-item-title>
          <v-list-item-subtitle>Västerås - Sweden</v-list-item-subtitle>
        </v-list-item-content>

        <v-img max-width="150" src="@/assets/hitachi.png"></v-img>
      </v-list-item>
      <v-card-text>
        <ul>
          <li>R&D of Hitachi's MACH control and protection DRTOS. (C/C++, Yocto)</li>
        </ul>
      </v-card-text>
    </v-card>
    <br>
     <!-- ------------------------------------------------------------------------ -->
    <v-card
      class="mx-auto"
      shaped
      elevation="3"
      max-width="98%"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">2020 - 2023</div>
          <v-list-item-title class="headline mb-1">PhD Candidate</v-list-item-title>
          <v-list-item-title class="title mb-1">Computer Science</v-list-item-title>
          <v-list-item-subtitle>Ecole Nationale de l'Aviation Civile / Onera, The French Aerospace Lab</v-list-item-subtitle>
          <v-list-item-subtitle>Institut National des sciences appliquées - Toulouse - France</v-list-item-subtitle>
          <v-list-item-subtitle>École doctorale Systèmes</v-list-item-subtitle>
        </v-list-item-content>

        <v-img max-width="150" src="@/assets/enac_grande.jpg"></v-img>
      </v-list-item>
      <v-card-text>
        <strong>Thesis: Modelling, Simulating and Emulating Distributed Applications in Swarms of Cyber-Physical Systems Deployed in Dynamic Networks</strong>
      </v-card-text>
    </v-card>
    <br>
     <!-- ------------------------------------------------------------------------ -->
    <v-card
      class="mx-auto"
      shaped
      elevation="3"
      max-width="98%"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">Nov 2018 - Jun 2019</div>
          <v-list-item-title class="headline mb-1">R&D Developer for SCADA System</v-list-item-title>
          <v-list-item-title class="title mb-1">EFACEC</v-list-item-title>
          <v-list-item-subtitle>Porto - Portugal</v-list-item-subtitle>
        </v-list-item-content>
      <v-img max-width="150" src="@/assets/efacec.png"></v-img>
      </v-list-item>
      <v-card-text>
        <ul>
          <li>R&D of the ScateX# software which is a SCADA system for Network Management. (C++,Bash and Java)</li>
        </ul>
      </v-card-text>
    </v-card>
    <br>
    <!-- ------------------------------------------------------------------------ -->
    <v-card
      class="mx-auto"
      shaped
      elevation="3"
      max-width="98%"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">Mar 2016 - Sep 2017</div>
          <v-list-item-title class="headline mb-1">Technical Service Specialist</v-list-item-title>
          <v-list-item-title class="title mb-1">ABB AS</v-list-item-title>
          <v-list-item-subtitle>Oslo - Norway</v-list-item-subtitle>
        </v-list-item-content>
      <v-img max-width="150" src="@/assets/abb.png"></v-img>
      </v-list-item>
      <v-card-text>
        <ul>
          <li>Technical responsible for service and maintenance of HVDC and FACTS plants in Norway (Onshore and Offshore)</li>
          <li>Control & Automation and Network conception and design for technical sales proposals of brown field projects</li>
        </ul>
      </v-card-text>
    </v-card>
    <br>
    <!-- ------------------------------------------------------------------------ -->
    <v-card
      class="mx-auto"
      shaped
      elevation="3"
      max-width="98%"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">Mar 2013 - Feb 2016</div>
          <v-list-item-title class="headline mb-1">Project Engineer – Power Systems / High Voltage Direct Current</v-list-item-title>
          <v-list-item-title class="title mb-1">ABB AB</v-list-item-title>
          <v-list-item-subtitle>Ludvika - Sweden</v-list-item-subtitle>
        </v-list-item-content>
      <v-img max-width="150" src="@/assets/abb.png"></v-img>
      </v-list-item>
      <v-card-text>
        <ul>
          <li>HVDC Substation control and automation development</li>
          <li>Control & Automation and Network design / conception for technical sales proposals of green field projects</li>
        </ul>
      </v-card-text>
    </v-card>
    <br>
    <!-- ------------------------------------------------------------------------ -->
    <v-card
      class="mx-auto"
      shaped
      elevation="3"
      max-width="98%"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">Nov 2011 - Feb 2013</div>
          <v-list-item-title class="headline mb-1">Commissioning Engineer – Power Systems / High Voltage Direct Current</v-list-item-title>
          <v-list-item-title class="title mb-1">ABB Ltda</v-list-item-title>
          <v-list-item-subtitle>Araraquara - Brazil</v-list-item-subtitle>
        </v-list-item-content>
      <v-img max-width="150" src="@/assets/abb.png"></v-img>
      </v-list-item>
      <v-card-text>
        <ul>
          <li>Installation and commissioning of Rio Madeira project's Araraquara station Control and Protection System</li>
          <li>Plant circuit diagram updating and Cabling supervision</li>
        </ul>
      </v-card-text>
    </v-card>
    <br>
    <!-- ------------------------------------------------------------------------ -->
    <v-card
      class="mx-auto"
      shaped
      elevation="3"
      max-width="98%"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">Mar 2010 - Oct 2011</div>
          <v-list-item-title class="headline mb-1">Project Engineer – Power Systems / High Voltage Direct Current</v-list-item-title>
          <v-list-item-title class="title mb-1">ABB AB</v-list-item-title>
          <v-list-item-subtitle>Ludvika - Sweden</v-list-item-subtitle>
        </v-list-item-content>
      <v-img max-width="150" src="@/assets/abb.png"></v-img>
      </v-list-item>
      <v-card-text>
        <ul>
          <li>Plant circuit diagram project design</li>
          <li>Control and automation system programming</li>
          <li>Factory System tests</li>
        </ul>
      </v-card-text>
    </v-card>
    <br>
    <!-- ------------------------------------------------------------------------ -->
    <v-card
      class="mx-auto"
      shaped
      elevation="3"
      max-width="98%"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">Oct 2007 - Feb 2010</div>
          <v-list-item-title class="headline mb-1">Project Engineer – Power Systems / Substation Automation</v-list-item-title>
          <v-list-item-title class="title mb-1">ABB Ltda</v-list-item-title>
          <v-list-item-subtitle>São Paulo - Brazil</v-list-item-subtitle>
        </v-list-item-content>
      <v-img max-width="150" src="@/assets/abb.png"></v-img>
      </v-list-item>
      <v-card-text>
        <ul>
          <li>Substation Automation and High Power Rectifiers control and automation, and network design and conception</li>
          <li>Factory tests and commissioning of the system</li>
          <li>Sales support</li>
        </ul>
      </v-card-text>
    </v-card>
    <br>
    <!-- ------------------------------------------------------------------------ -->
    <v-card
      class="mx-auto"
      shaped
      elevation="3"
      max-width="98%"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">Sep 2006 - Sep 2007</div>
          <v-list-item-title class="headline mb-1">Project Engineer – Power Systems / Substation Automation</v-list-item-title>
          <v-list-item-title class="title mb-1">Axiom Eletronics</v-list-item-title>
          <v-list-item-subtitle>São Paulo - Brazil</v-list-item-subtitle>
        </v-list-item-content>
      <v-img max-width="150" src="@/assets/axiom.png"></v-img>
      </v-list-item>
      <v-card-text>
        <ul>
          <li>Development of Substation automation projects</li>
          <li>Programming of HMIs with MicroSCADA.</li>
          <li>Factory tests and Commissioning</li>
        </ul>
      </v-card-text>
    </v-card>
    <br>
  </v-flex>
</template>

<script>
export default {

}
</script>

<style>

</style>
